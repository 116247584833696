import { useState } from "react";
import { getAuditProtocolUserRoleAPI } from "../../../api/admin";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { getUsersByIdAPI } from "../../../api/identity";
import { getStudyRolesByIdAPI } from "../../../api/security";

export const useAuditsUserRole = (protocolId) => {
    const [audits, setAudits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async (userId) => {
        setIsLoading(true);
        try {
            setIsLoading(true);
            var response = await getAuditProtocolUserRoleAPI(protocolId, userId);
            if (!response.data)
                return null;
            
            // Obtengo datos del usuario q realizó la acción
            var usersResponse = await getUsersByIdAPI(response.data.map(x => { return x.actionBy }))
            if (usersResponse.data) {
                response.data.forEach(item => {
                    item.actionByUser = usersResponse.data.find(x => x.userId == item.actionBy)
                });
            }

            // Obtengo datos del Rol
            var rolesResponse = await getStudyRolesByIdAPI(response.data.map(x => { return x.roleId }))
            if (rolesResponse.data) {
                response.data.forEach(item => {
                    item.role = rolesResponse.data.find(x => x.id == item.roleId)
                });
            }
            console.log(response.data)

            setAudits(response.data);
            setIsLoading(false);
        }
        catch (error) {
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    return [fetchData, audits, isLoading];
}