import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from 'react-i18next';
import { putDrug, removeDrugAPI } from '../../../api/medicalRecord';
import { AuditModal, CreateAudit, CreateAuditModal } from "../../../components/AuditTrail/AuditModal";
import { ExpandableContent } from "../../../components/CustomControls/ExpandableContent";
import { Drugs } from "../../../components/MedicalRecords/Drugs";
import { DrugsForm } from "../../../components/MedicalRecords/Forms/DrugsForm";
import { ModalData } from '../../../components/Modals/ModalData';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { getMedicalRecordsSchema } from "../../../components/Validations/FormValidationSchema";
import { AuditTrail } from "../../Admin/AuditTrail";
import { useDrug, useDrugs } from "../Hooks/useDrugs";
import { ModalConfirm } from '../../../components/Modals/ModalConfirm';
import { Success } from '../../../components/Notifications/Notifications';
import { ModalSpinner } from '../../../components/Utils/Loaders';
import { ModalDuplicatedItem } from '../../../components/MedicalRecords/ModalDuplicatedItem';

export const DrugsContainer = (props) => {
    const [content, setContent] = useState(null);
    const { entryStatus, entryId, medicalRecordNumber, expandedAll } = props;
    const [drugs, query, totalSize, isLoading, handleTableChange, setReload] = useDrugs(medicalRecordNumber, entryId, true);
    const [, , , create] = useDrug();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.contentToReload?.contentName === "drugs")
            setReload(true);
    }, [props.contentToReload]);

    const handleEdit = (id) => {
        setContent(<DrugEditContainer
            medicalRecordNumber={medicalRecordNumber}
            entryId={entryId}
            id={id}
            onSubmit={handleSubmit}
            onCancel={handleClose}
        />);
    }

    const handleSubmit = async (data) => {

        if (!data || !data.drugs || data.drugs.length === 0)
            return;

        if (entryStatus !== "Published" && entryStatus !== "AutoPublished") {
            onConfirmSubmit(data);
        } else {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (data && data.drugs && data.drugs.length > 0) {
                data.drugs.forEach(x => (x.reason && (x.reasonId = x.reason.id))); // Mapeo Reason
                let drug = data.drugs[0];
                if (reason) {
                    drug.auditReason = reason;
                    drug.auditComments = comments;
                }
                await putDrug(entryId, drug.id, drug);
                setContent(null);
                setReload(true);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleClose = () => { setContent(null); }

    const handleAuditTrail = (entityId) => {
        setContent(
            <AuditModal
                onClose={() => { setContent(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditTrail entity="Drugs" entityId={entityId} medicalRecordNumber={medicalRecordNumber} />
            </AuditModal>
        )
    }

    const handleAddDrug = (item) => {
        setContent(
            <ModalData
                title={t("medicalRecords.drugs.addClose")}
                isShowing={true}
                size="lg"
                className=""
                hide={handleClose}
            >
                <DrugEditContainer
                    medicalRecordNumber={medicalRecordNumber}
                    entryId={entryId}
                    id={item.id}
                    onSubmit={handleSubmitDrug}
                    onCancel={handleClose}
                />
            </ModalData>
        );
    }

    const handleSubmitDrug = (data) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmitDrug(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmitDrug(data);
        }
    }

    const onConfirmSubmitDrug = async (data, reason, comments) => {
        handleClose();

        if (data && data.drugs && data.drugs.length > 0) {
            data.drugs.forEach(x => (x.reason && (x.reasonId = x.reason.id))); // Mapeo Reason
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            try {
                await create(entryId, data);
                if (props.onSubmit)
                    props.onSubmit("drugs") // Llamo a refresh para que se actualice el componente Diagnoses. 
            }
            catch (error) {
                HandleApiError(error);
            }
        }
    }

    //#region Delete item with audit
    const handleRemove = (id) => {
        setContent(
            <ModalConfirm
                onConfirm={() => confirmRemoveItem(id)}
                onCancel={() => setContent(null)}
                title={t("medicalRecordsNotifications.item_delete_title")}
                description={t("medicalRecordsNotifications.item_delete_description")}
                confirmStyle="danger"
            />);
    }
    const confirmRemoveItem = (id) => {
        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAuditModal
                onClose={() => setContent(null)}
                onSave={(reason, comments) => _removeItemAudited(id, reason, comments)}
            />)
        }
        else {
            _removeItemAudited(id, null, null);
        }
    }
    const _removeItemAudited = async (id, reason, comments) => {
        try {
            setContent(<ModalSpinner isShowing={true} hide={null} />);
            await removeDrugAPI(entryId, id, reason?.code, comments);
            setReload(true);
            setContent(null);
            Success("medicalRecordsNotifications.item_DeletedSuccessfully");
        }
        catch (error) {
            setContent(null);
            console.log(error);
            HandleApiError(error);
        }
    }
    //#endregion

    if (totalSize === 0)
        return null;

    return (
        <ExpandableContent title={t("medicalRecords.drugs.title")} expandedAll={expandedAll} content="drugs">
            {content}
            <Drugs
                withCard={false}
                entryId={entryId}
                data={drugs}
                query={query}
                totalSize={totalSize}
                isLoading={isLoading}
                onTableChange={handleTableChange}
                onEdit={handleEdit}
                onAuditTrail={handleAuditTrail}
                onAddDrug={handleAddDrug}
                onRemove={handleRemove}
            />
        </ExpandableContent>
    );
}

export const DrugCreateContainer = (props) => {
    const { entryStatus, entryId, medicalRecordNumber, onSubmit } = props;
    const [, masterData, isLoading, create] = useDrug(medicalRecordNumber, entryId);
    const [content, setContent] = useState(null);

    const handleSubmit = async (data) => {

        if (entryStatus === "Published" || entryStatus === "AutoPublished") {
            setContent(<CreateAudit
                onClose={() => setContent(null)}
                onSave={(reason, comments) => onConfirmSubmit(data, reason, comments)}
            />)
        }
        else {
            onConfirmSubmit(data);
        }
    }

    const onConfirmSubmit = async (data, reason, comments) => {
        try {
            if (reason) {
                data.auditReason = reason;
                data.auditComments = comments;
            }
            if (data && data.drugs && data.drugs.length > 0) {
                data.drugs.forEach(x => (x.reason && (x.reasonId = x.reason.id))); // Mapeo Reason

                const resp = await create(entryId, data);
                if (resp.data.length > 0)
                    setContent(<ModalDuplicatedItem
                        onHide={() => { setContent(null); onSubmit("drugs"); }}
                        items={resp.data}
                        item="drugs"
                        medicalRecordNumber={props.medicalRecordNumber}
                    />);
                else {
                    setContent(null);
                    onSubmit("drugs"); // Indico al padre sobre q contenido hacer Reload. 
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCancel = () => {
        props.hide()
    }
    if (!masterData || isLoading)
        return null;

    return (
        <>
            {
                content ??
                <DrugContainerFormik
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                    data={masterData.data}
                    events={masterData.events}
                />
            }
        </>

    );
}

const DrugEditContainer = (props) => {
    const { medicalRecordNumber, entryId, id, onSubmit, onCancel } = props;
    const [data, masterData, isLoading] = useDrug(medicalRecordNumber, entryId, id);

    if (!data || !masterData || isLoading)
        return null;

    return (
        <DrugContainerFormik
            onSubmit={onSubmit}
            onCancel={onCancel}
            drug={data}
            data={masterData.data}
            events={masterData.events}
        />
    );
}

const DrugContainerFormik = withTranslation()(withFormik({
    mapPropsToValues: (props) => (props.drug && { drugs: [props.drug] }),

    validationSchema: getMedicalRecordsSchema().drugsEdit,

    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values);
    },

    displayName: 'DrugsForm',

})(DrugsForm));

DrugContainerFormik.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    drug: PropTypes.object.isRequired,
};