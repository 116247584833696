import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from 'react-i18next';
import { patientInProtocolStatus, visitStage, visitStatus, visitTypes } from "../../../variables/Enums";
import { WithAuth } from "../../Authorization/WithAuthProtocol";
import Card from "../../Cards/Card";
import { Audit, Back, Drug, Export, Publish, Remove, Save, Warning } from "../../Icons/Icons";


export const FormActions = (props) => {
    const {
        onBack,
        onSave,
        onAuditTrail,
        onScreenFailure,
        onWithoutDrugs,
        onDiscontinuation,
        onRemove,
        onExport,
        visit,
        onEndPatientInProtocol,
        onRollbackVisitStatus,
        protocolId,
        isSubmitting
    } = props;

    const { t } = useTranslation();
    if (!visit)
        return null;

    return (
        <Card
            title={t('medicalRecords.actions')}
            className="card-actions"
            content={
                <>
                    <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.Add"]}>
                        <button
                            type="button"
                            disabled={isSubmitting}
                            className="btn btn-primary btn-full "
                            onClick={() => onSave()}
                        >
                            <Save style={{ marginRight: '5px' }} />{t('protocols.visitTracking.saveChanges')}
                        </button>
                    </WithAuth>
                    <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.ExportPatientVisit"]}>
                        <button
                            type="button"
                            className="btn btn-success btn-full "
                            onClick={onExport}
                            disabled={isSubmitting}
                        >
                            <Export style={{ marginRight: '5px' }} />{t('protocols.visitTracking.export')}
                        </button>
                    </WithAuth>
                    <WithAuth protocolId={protocolId} requiredPermission={["VisitTracking.Publish"]}>
                        {   // Publicación de Visita.
                            (visit.type !== visitTypes.EarlyCompletion &&
                                visit.status !== visitStatus.Failed && visit.status !== visitStatus.Skipped && visit.status !== visitStatus.Completed) &&
                            <button
                                disabled={isSubmitting}
                                type="submit"
                                className="btn btn-success btn-full"
                            >
                                <Publish style={{ marginRight: '5px' }} />
                                {visit.status === 1 ? t('protocols.visitTracking.publishChanges') : t('protocols.visitTracking.publish')}
                            </button>
                        }
                        { // Eliminar visita. 
                            visit.status === visitStatus.Started &&
                            <button
                                type="button"
                                name="remove"
                                className="btn btn-danger btn-full"
                                onClick={() => onRemove()}
                                disabled={isSubmitting}
                            >
                                <Remove style={{ marginRight: '5px' }} />{t('protocols.visitTracking.remove')}
                            </button>
                        }
                        {
                            // Terminar participación del paciente en el protocolo
                            visit.stage === visitStage.FollowUp &&
                            <button
                                type="button"
                                className="btn btn-warning btn-full "
                                onClick={onEndPatientInProtocol}
                                disabled={isSubmitting || visit.status >= 1 ? true : false}
                            >
                                <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.endPatientInProtocol')}
                            </button>

                        }
                        { // Sin Medicacion
                            (visit.type === visitTypes.EarlyCompletion) &&
                            (visit.status === undefined || visit.status === 0) &&
                            <button
                                type="button"
                                className="btn btn-info btn-full "
                                onClick={onWithoutDrugs}
                                disabled={isSubmitting || visit.status >= 1 ? true : false}
                            >
                                <Drug style={{ marginRight: '5px' }} />{t('protocols.visitTracking.withoutDrugs')}
                            </button>
                        }
                        { // Rollback Visit Status
                            (visit.status === visitStatus.Failed || (visit.status === visitStatus.Completed && props.patientInProtocolStatus === patientInProtocolStatus.EndParticipationInProtocol)) &&
                            (
                                props.patientInProtocolStatus === patientInProtocolStatus.Failed ||
                                props.patientInProtocolStatus === patientInProtocolStatus.LostToFollowUp ||
                                props.patientInProtocolStatus === patientInProtocolStatus.Discontinuation ||
                                props.patientInProtocolStatus === patientInProtocolStatus.EndParticipationInProtocol
                            ) &&
                            <button
                                type="button"
                                className="btn btn-info btn-full "
                                onClick={onRollbackVisitStatus}
                                disabled={isSubmitting}
                            >
                                <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.rollbackStatus')}
                            </button>
                        }
                        { // Discontinuacion
                            (visit.type === visitTypes.EarlyCompletion) &&
                            (visit.status === undefined || visit.status === 0) &&
                            <button
                                type="button"
                                className="btn btn-danger btn-full"
                                onClick={onDiscontinuation}
                                disabled={isSubmitting || visit.status >= 1 ? true : false}
                            >
                                <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.discontinuation')}
                            </button>
                        }
                        {   // Falla de Screening.
                            (visit.stage === visitStage.Screening || visit.stage === visitStage.Random) &&
                            visit.type !== visitTypes.EarlyCompletion && (visit.status === undefined || visit.status === 0) &&
                            <button
                                type="button"
                                className="btn btn-danger btn-full "
                                onClick={onScreenFailure}
                                disabled={isSubmitting || visit.status >= 1 ? true : false}
                            >
                                <Warning style={{ marginRight: '5px' }} />{t('protocols.visitTracking.screenFailure')}
                            </button>
                        }
                    </WithAuth>
                    <button className={`btn btn-default btn-fill btn-full`} onClick={onBack}>
                        <Back /> {t('buttons.back')}
                    </button>
                    <button className={`btn btn-default btn-fill btn-full`} onClick={props.onBackToMR}>
                        <Back /> Ir a H.C.
                    </button>
                    <button
                        type="button"
                        className="btn btn-audit btn-fill btn-full"
                        onClick={() => onAuditTrail()}
                    >
                        <Audit style={{ marginRight: '5px' }} />{t('protocols.visitTracking.auditTrail')}
                    </button>
                </>
            }
        />
    );
}

FormActions.propTypes = {
    onBack: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onAuditTrail: PropTypes.func.isRequired,
    onScreenFailure: PropTypes.func.isRequired,
    visit: PropTypes.object,
    protocolId: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
};