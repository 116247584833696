import axios from './index';

export const getPagingAndOrderParams = (newActiveFilters, searchParams) => {

    if (!newActiveFilters)
        return;

    searchParams.delete('pageIndex'); // se resetea el paginado
    if (newActiveFilters.pagination?.page) {
        searchParams.append('pageIndex', newActiveFilters.pagination.page);
    }
    searchParams.delete('pageSize');
    if (newActiveFilters.pagination?.sizePerPage) {
        searchParams.append('pageSize', newActiveFilters.pagination.sizePerPage);
    }
    searchParams.delete('sortField');
    if (newActiveFilters.sort?.sortField) {
        searchParams.append('sortField', newActiveFilters.sort.sortField);
    }
    searchParams.delete('sortOrder');
    if (newActiveFilters.sort?.sortOrder) {
        searchParams.append('sortOrder', newActiveFilters.sort.sortOrder);
    }

    return searchParams;
}

export const getQueryString = (query, search) => {

    var searchParams = new URLSearchParams(search || '');

    if (!query)
        return searchParams.toString();

    searchParams = getPagingAndOrderParams(query, searchParams);

    if (query.filters) {
        for (const property in query.filters) {

            searchParams.delete(property);
            if (query.filters[property] !== null && query.filters[property] !== undefined) {
                searchParams.append(property, query.filters[property]);
            }
        }
    }

    if (query.query) {
        searchParams.delete("q");
        if (query.query) {
            searchParams.append("q", query.query);
        }
    }

    if (query.onlyDoctor != null && query.onlyDoctor != undefined) {
        searchParams.delete("onlyDoctor");
        searchParams.append("onlyDoctor", query.onlyDoctor);
    }

    return searchParams.toString();
}

const transformRequestOptions = (params) => {
    let options = '';
    params.ids.forEach((id) => {
        options += `ids=${id}&`;
    });
    return options;
};

export const getRoleMasterData = () => {
    return axios.get(`api/v1/authorization/masterdata`);
};
export const getRoles = (filters) => {
    const queryString = getQueryString(filters, window.location.search);
    return axios.get(queryString ? `api/v1/authorization?${queryString}` : 'api/v1/authorization');
};
export const getRole = (roleId) => {
    return axios.get(`api/v1/authorization/${roleId}`);
};
export const createRole = (role) => {
    return axios.post(`api/v1/authorization`, role);
};
export const editRole = (roleId, role) => {
    return axios.put(`api/v1/authorization/${roleId}`, role);
};
export const removeRole = (roleId) => {
    return axios.delete(`api/v1/authorization/${roleId}`);
};

export const getStudyRoleMasterData = () => {
    return axios.get(`api/v1/protocols/masterdata`);
};
export const getStudyRoles = () => {
    return axios.get(`api/v1/protocols/studyRoles`);
};
export const getStudyRolesByIdAPI = (roles) => {
    return axios.get(`api/v1/protocols/studyRolesById`, {
        params: { ids: roles },
        paramsSerializer: transformRequestOptions,
    });
};
export const getStudyRole = (roleId) => {
    return axios.get(`api/v1/protocols/studyRoles/${roleId}`);
};
export const createStudyRole = (role) => {
    return axios.post(`api/v1/protocols/studyRoles`, role);
};
export const editStudyRole = (roleId, role) => {
    return axios.put(`api/v1/protocols/studyRoles/${roleId}`, role);
};
export const removeStudyRole = (roleId) => {
    return axios.delete(`api/v1/protocols/studyRoles/${roleId}`);
};
export const getCurrentUserRole = (protocolId) => {
    return axios.get(`api/v1/protocols/${protocolId}/studyRoles/currentUser`);
};